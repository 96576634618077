import React from "react"
import { graphql } from "gatsby"
import { Container, Div, StyleReset, Text, ThemeProvider } from "atomize"
import Header from "../components/header"
import { theme } from "../settings/config"
import SEO from "../components/seo"
import Footer from "../components/footer"

export default function BlogPost({ data }) {
    const post = data.markdownRemark
    const disqusConfig = {
        shortname: "squiseat",
        config: {
            identifier: post.id,
            title: post.frontmatter.title,
            url: `https://squiseat.it/blog/${post.frontmatter.slug}`,
        },
    }

    return (
        <ThemeProvider theme={theme}>
            <StyleReset />
            <Header />
            <SEO
                title={post?.frontmatter?.title}
                description={post?.frontmatter?.description || post?.frontmatter?.title}
                ogImage={post?.frontmatter?.thumbnail}
                pathname={post.frontmatter.slug}
            />
            <main style={{ position: "relative", overflow: "hidden" }}>
                <Div pos="relative" p={{ t: { xs: "1rem", lg: "3rem" } }}>
                    <Div
                        bg="white"
                        pos="relative"
                        zIndex="1"
                        p={{
                            t: {
                                xs: "0",
                                md: "1rem",
                                lg: "6rem",
                                xl: "7rem",
                            },
                        }}
                    >
                        <Container pos="relative">
                            <Text
                                tag="h1"
                                textSize={{ xs: "display1", lg: "display2" }}
                                p={{ b: "2rem" }}
                                textColor="gunmetal"
                            >
                                {post.frontmatter.title}
                            </Text>
                            <Div p={{ x: "0.5rem", b: "5rem" }}>
                                <div className="post_body" dangerouslySetInnerHTML={{ __html: post.html }} />
                            </Div>
                        </Container>
                    </Div>
                </Div>
            </main>
            <Footer />
        </ThemeProvider>
    )
}

export const query = graphql`
    query($slug: String!) {
        markdownRemark(frontmatter: { slug: { eq: $slug } }) {
            html
            frontmatter {
                title
                description
                thumbnail
                slug
            }
            id
        }
    }
`
